import {
  HideWhenScreenIs,
  OreganoButton,
  OreganoIcon,
  OreganoSelect,
  OreganoText,
} from "@/components/foundation";
import { useSelectContext } from "@/components/foundation/Select/context/SelectContext";
import {
  LocationDotIcon,
  CheckIcon,
  XmarkIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowRightIcon,
} from "@/public/icons/solid";
import { useState, PropsWithChildren } from "react";
import { EnabledIcon } from "./components";
import {
  CITY_CONTENT_MAP,
  useGetComparisonRates,
  ToCity,
} from "@/hooks/signUp/useGetComparisonRates";
import { useLayoutContext } from "@/context/LayoutContext";

export const ComparisonWidget = () => {
  const [toCity, setToCity] = useState<ToCity>("NYC");
  const { getComparisonRates } = useGetComparisonRates();
  const [comparisonRates, setComparisonRates] = useState(
    getComparisonRates("ATL", toCity),
  );
  const { scrollbarWidth } = useLayoutContext();

  const numberOfDaysFaster =
    comparisonRates["otherApps"].speedInDays -
    comparisonRates["parsel"].speedInDays;

  const discountPercentage =
    (comparisonRates["retail"].labelCostInCents -
      comparisonRates["parsel"].labelCostInCents) /
    comparisonRates["retail"].labelCostInCents;

  return (
    <div className="flex flex-col gap">
      <div className="flex flex-row items-center justify-center gap-sm">
        <div className="flex w-full flex-row justify-end">
          <OreganoButton
            shape="pill"
            variant="secondary"
            className="pl-xs pr-sm"
          >
            <div className="flex flex-row items-center gap-xs">
              <div className="flex flex-row items-center gap-2xs">
                <OreganoIcon size="sm">
                  <LocationDotIcon />
                </OreganoIcon>
                <OreganoText>From</OreganoText>
              </div>
              <HideWhenScreenIs smallest xs sm>
                <OreganoText variant="headingSm">Atlanta</OreganoText>
              </HideWhenScreenIs>
              <HideWhenScreenIs md lg>
                <OreganoText variant="headingSm">ATL</OreganoText>
              </HideWhenScreenIs>
            </div>
          </OreganoButton>
        </div>
        <OreganoIcon size="sm">
          <ArrowRightIcon />
        </OreganoIcon>
        <div className="w-full">
          <div className="inline-block">
            <OreganoSelect
              activator={
                <Activator>
                  <div className="flex flex-row items-center gap-xs pr-xs">
                    <div className="flex flex-row items-center gap-2xs">
                      <OreganoIcon size="sm">
                        <LocationDotIcon />
                      </OreganoIcon>
                      <OreganoText>To</OreganoText>
                    </div>
                    <HideWhenScreenIs smallest xs sm>
                      <OreganoText variant="headingSm">
                        {CITY_CONTENT_MAP[toCity]}
                      </OreganoText>
                    </HideWhenScreenIs>
                    <HideWhenScreenIs md lg>
                      <OreganoText variant="headingSm">
                        {toCity.toUpperCase()}
                      </OreganoText>
                    </HideWhenScreenIs>
                  </div>
                </Activator>
              }
              onSelect={(targetCity: ToCity) => {
                setToCity(targetCity);
                setComparisonRates(getComparisonRates("ATL", targetCity));
              }}
              options={Object.keys(CITY_CONTENT_MAP).map((key) => {
                return {
                  key,
                  value: key as ToCity,
                  active: key === toCity,
                  content: (
                    <OreganoText>{CITY_CONTENT_MAP[key as ToCity]}</OreganoText>
                  ),
                };
              })}
            />
          </div>
        </div>
      </div>
      <HideWhenScreenIs smallest xs sm>
        <div className="mx-auto max-w-[600px]">
          <table className="w-full table-fixed overflow-hidden rounded bg-[#272F34] drop-shadow-lg">
            <thead>
              <tr className="border-b border-[#434E54]">
                <th></th>
                <th className="py pr-sm text-left">
                  <OreganoText variant="headingSm" color="lightSubdued">
                    Label cost
                  </OreganoText>
                </th>
                <th className="py pr-sm text-left">
                  <OreganoText variant="headingSm" color="lightSubdued">
                    Speed
                  </OreganoText>
                </th>
                <th className="py pr-sm text-left">
                  <OreganoText variant="headingSm" color="lightSubdued">
                    On-demand pickup
                  </OreganoText>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-[#434E54]">
                <td className="px pb-800 pt-sm align-top">
                  <OreganoText color="light" variant="bodyLg">
                    Parsel
                  </OreganoText>
                </td>
                <td className="pb-800 pr pt-sm align-top">
                  <div className="flex flex-col gap-2xs">
                    <OreganoText color="light" variant="bodyLg">
                      $
                      {(
                        comparisonRates["parsel"].labelCostInCents / 100
                      ).toFixed(2)}
                    </OreganoText>
                    {discountPercentage > 0 && (
                      <OreganoText color="lightAccent" variant="bodySm">
                        Up to {Math.round(discountPercentage * 100)}% savings{" "}
                        <span className="text-light-subdued">
                          compared to retail rates
                        </span>
                      </OreganoText>
                    )}
                  </div>
                </td>
                <td className="pb-800 pr pt-sm align-top">
                  <div className="flex flex-col gap-2xs">
                    <OreganoText color="light" variant="bodyLg">
                      {`${comparisonRates["parsel"].speedInDays} day${comparisonRates["parsel"].speedInDays != 1 && "s"}`}
                    </OreganoText>
                    {numberOfDaysFaster > 0 && (
                      <OreganoText color="lightAccent" variant="bodySm">
                        {`Up to ${numberOfDaysFaster} day${numberOfDaysFaster != 1 ? "s" : ""} faster`}
                      </OreganoText>
                    )}
                  </div>
                </td>
                <td className="flex flex-col gap-2xs pb-800 pr pt-sm align-top">
                  <div className="flex flex-col gap-2xs">
                    <EnabledIcon
                      enabled={comparisonRates["parsel"].onDemandPickup}
                    />
                    <OreganoText color="lightAccent" variant="bodySm">
                      Up to 5 free pickups
                    </OreganoText>
                  </div>
                </td>
              </tr>
              <tr className="border-b border-[#434E54] bg-[#353E43]">
                <td className="px py-sm pb-800 align-top">
                  <OreganoText color="light" variant="bodyLg">
                    Other apps
                  </OreganoText>
                </td>
                <td className="py-sm pb-800 pr align-top">
                  <OreganoText color="light" variant="bodyLg">
                    $
                    {(
                      comparisonRates["otherApps"].labelCostInCents / 100
                    ).toFixed(2)}
                  </OreganoText>
                </td>
                <td className="py-sm pb-800 pr align-top">
                  <OreganoText color="light" variant="bodyLg">
                    {`${comparisonRates["otherApps"].speedInDays} day${comparisonRates["otherApps"].speedInDays != 1 && "s"}`}
                  </OreganoText>
                </td>
                <td className="py-sm pb-800 pr align-top">
                  <EnabledIcon
                    enabled={comparisonRates["otherApps"].onDemandPickup}
                  />
                </td>
              </tr>
              <tr className="bg-[#353E43]">
                <td className="px py-sm pb-800 align-top">
                  <OreganoText color="light" variant="bodyLg">
                    Retail
                  </OreganoText>
                </td>
                <td className="py-sm pb-800 pr align-top">
                  <div className="h-full">
                    <OreganoText color="light" variant="bodyLg">
                      $
                      {(
                        comparisonRates["retail"].labelCostInCents / 100
                      ).toFixed(2)}
                    </OreganoText>
                  </div>
                </td>
                <td className="py-sm align-top">
                  <div className="h-full">
                    <OreganoText color="light" variant="bodyLg">
                      {`${comparisonRates["retail"].speedInDays} day${comparisonRates["retail"].speedInDays != 1 ? "s" : ""}`}
                    </OreganoText>
                  </div>
                </td>
                <td className="py-sm pb-800 pr align-top">
                  <EnabledIcon
                    enabled={comparisonRates["retail"].onDemandPickup}
                  />
                  <OreganoText color="light" variant="bodyLg"></OreganoText>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </HideWhenScreenIs>
      <HideWhenScreenIs md lg>
        <div className="mx-auto grid w-full max-w-[500px] grid-cols-2 overflow-hidden rounded drop-shadow-lg">
          <div
            className="grid auto-rows-auto bg-[#272F34]"
            style={{ paddingBottom: `${scrollbarWidth}px` }}
          >
            <div className="border-b border-[#434E54] p">
              <OreganoText color="light" variant="bodyLg">
                Parsel
              </OreganoText>
            </div>
            <div className="flex flex-col gap-2xs border-b border-[#434E54] p">
              <OreganoText color="lightSubdued" variant="bodySm">
                Label cost
              </OreganoText>
              <OreganoText color="light" variant="bodyLg">
                ${(comparisonRates["parsel"].labelCostInCents / 100).toFixed(2)}
              </OreganoText>
              {discountPercentage > 0 && (
                <OreganoText color="lightAccent" variant="bodySm">
                  Up to {Math.round(discountPercentage * 100)}% savings{" "}
                  <span className="text-light-subdued">
                    compared to retail rates
                  </span>
                </OreganoText>
              )}
            </div>
            <div className="flex flex-col gap-2xs border-b border-[#434E54] p">
              <OreganoText color="lightSubdued" variant="bodySm">
                Speed
              </OreganoText>
              <OreganoText color="light" variant="bodyLg">
                {`${comparisonRates["parsel"].speedInDays} day${comparisonRates["parsel"].speedInDays != 1 && "s"}`}
              </OreganoText>
              {numberOfDaysFaster > 0 && (
                <OreganoText color="lightAccent" variant="bodySm">
                  {`Up to ${numberOfDaysFaster} day${numberOfDaysFaster != 1 ? "s" : ""} faster`}
                </OreganoText>
              )}
            </div>
            <div className="flex flex-col gap-2xs p">
              <OreganoText color="lightSubdued" variant="bodySm">
                On-demand pickup
              </OreganoText>
              <EnabledIcon enabled={comparisonRates["parsel"].onDemandPickup} />
              <OreganoText color="lightAccent" variant="bodySm">
                Up to 5 free pickups
              </OreganoText>
            </div>
          </div>
          <div className="grid grid-cols-[100%_100%] overflow-x-scroll bg-[#353E43]">
            <div className="grid auto-rows-auto">
              <div className="border-b border-[#434E54] p">
                <OreganoText color="light" variant="bodyLg">
                  Other apps
                </OreganoText>
              </div>
              <div className="flex flex-col gap-2xs border-b border-[#434E54] p">
                <OreganoText color="lightSubdued" variant="bodySm">
                  Label cost
                </OreganoText>
                <OreganoText color="light" variant="bodyLg">
                  $
                  {(
                    comparisonRates["otherApps"].labelCostInCents / 100
                  ).toFixed(2)}
                </OreganoText>
                {/* purely for sizing */}
                {discountPercentage > 0 && (
                  <OreganoText
                    color="lightAccent"
                    variant="bodySm"
                    className="pointer-events-none opacity-0"
                  >
                    Up to {Math.round(discountPercentage * 100)}% savings{" "}
                    <span className="text-light-subdued">
                      compared to retail rates
                    </span>
                  </OreganoText>
                )}
              </div>
              <div className="flex flex-col gap-2xs border-b border-[#434E54] p">
                <OreganoText color="lightSubdued" variant="bodySm">
                  Speed
                </OreganoText>
                <OreganoText color="light" variant="bodyLg">
                  {`${comparisonRates["otherApps"].speedInDays} day${comparisonRates["otherApps"].speedInDays != 1 && "s"}`}
                </OreganoText>
                {/* purely for sizing */}
                {numberOfDaysFaster > 0 && (
                  <OreganoText
                    color="lightAccent"
                    variant="bodySm"
                    className="pointer-events-none opacity-0"
                  >
                    {`Up to ${numberOfDaysFaster} day${numberOfDaysFaster != 1 ? "s" : ""} faster`}
                  </OreganoText>
                )}
              </div>
              <div className="flex flex-col gap-2xs p">
                <OreganoText color="lightSubdued" variant="bodySm">
                  On-demand pickup
                </OreganoText>
                <EnabledIcon
                  enabled={comparisonRates["otherApps"].onDemandPickup}
                />
                <OreganoText
                  color="lightAccent"
                  variant="bodySm"
                  className="pointer-events-none opacity-0"
                >
                  Up to 5 free pickups
                </OreganoText>
              </div>
            </div>
            <div className="grid auto-rows-auto">
              <div className="border-b border-[#434E54] p">
                <OreganoText color="light" variant="bodyLg">
                  Retail
                </OreganoText>
              </div>
              <div className="flex flex-col gap-2xs border-b border-[#434E54] p">
                <OreganoText color="lightSubdued" variant="bodySm">
                  Label cost
                </OreganoText>
                <OreganoText color="light" variant="bodyLg">
                  $
                  {(comparisonRates["retail"].labelCostInCents / 100).toFixed(
                    2,
                  )}
                </OreganoText>
                {/* purely for sizing */}
                {discountPercentage > 0 && (
                  <OreganoText
                    color="lightAccent"
                    variant="bodySm"
                    className="pointer-events-none opacity-0"
                  >
                    Up to {Math.round(discountPercentage * 100)}% savings{" "}
                    <span className="text-light-subdued">
                      compared to retail rates
                    </span>
                  </OreganoText>
                )}
              </div>
              <div className="flex flex-col gap-2xs border-b border-[#434E54] p">
                <OreganoText color="lightSubdued" variant="bodySm">
                  Speed
                </OreganoText>
                <OreganoText color="light" variant="bodyLg">
                  {`${comparisonRates["retail"].speedInDays} day${comparisonRates["retail"].speedInDays != 1 && "s"}`}
                </OreganoText>
                {/* purely for sizing */}
                {numberOfDaysFaster > 0 && (
                  <OreganoText
                    color="lightAccent"
                    variant="bodySm"
                    className="pointer-events-none opacity-0"
                  >
                    {`Up to ${numberOfDaysFaster} day${numberOfDaysFaster != 1 ? "s" : ""} faster`}
                  </OreganoText>
                )}
              </div>
              <div className="flex flex-col gap-2xs p">
                <OreganoText color="lightSubdued" variant="bodySm">
                  On-demand pickup
                </OreganoText>
                <EnabledIcon
                  enabled={comparisonRates["retail"].onDemandPickup}
                />
                <OreganoText
                  color="lightAccent"
                  variant="bodySm"
                  className="pointer-events-none opacity-0"
                >
                  Up to 5 free pickups
                </OreganoText>
              </div>
            </div>
          </div>
        </div>
      </HideWhenScreenIs>
    </div>
  );
};

const Activator = ({ children }: PropsWithChildren) => {
  const { activatorRef, isPopoverVisible, setIsPopoverVisible, handleKeyDown } =
    useSelectContext();

  return (
    <OreganoButton
      shape="pill"
      variant="secondary"
      onClick={() => setIsPopoverVisible(!isPopoverVisible)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={activatorRef}
      className="px-xs"
    >
      {children}
      {
        <OreganoIcon size="xs" fill="subdued">
          {isPopoverVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </OreganoIcon>
      }
    </OreganoButton>
  );
};
