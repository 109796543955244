export type FromCity = "ATL";
export type ToCity = "NYC" | "DAL" | "CHI" | "MIA" | "LA";

export const CITY_CONTENT_MAP: Record<ToCity, string> = {
  NYC: "New York City",
  DAL: "Dallas",
  CHI: "Chicago",
  MIA: "Miami",
  LA: "Los Angeles",
};

interface ServiceFeatures {
  labelCostInCents: number;
  speedInDays: number;
  onDemandPickup: boolean;
}

type LocationComparisonRate = Record<
  "parsel" | "otherApps" | "retail",
  ServiceFeatures
>;

// named as a hook in case we want to actually make api calls in the future
export const useGetComparisonRates = () => {
  const getComparisonRates = (
    fromCity: FromCity,
    toCity: ToCity,
  ): LocationComparisonRate => {
    return STATIC_SAMPLE_RATES[fromCity][toCity];
  };

  return {
    getComparisonRates,
  };
};

const STATIC_SAMPLE_RATES: Record<
  FromCity,
  Record<ToCity, LocationComparisonRate>
> = {
  ATL: {
    NYC: {
      parsel: {
        labelCostInCents: 607,
        speedInDays: 2,
        onDemandPickup: true,
      },
      otherApps: {
        labelCostInCents: 812,
        speedInDays: 3,
        onDemandPickup: false,
      },
      retail: {
        labelCostInCents: 1245,
        speedInDays: 3,
        onDemandPickup: false,
      },
    },
    DAL: {
      parsel: {
        labelCostInCents: 607,
        speedInDays: 2,
        onDemandPickup: true,
      },
      otherApps: {
        labelCostInCents: 812,
        speedInDays: 3,
        onDemandPickup: false,
      },
      retail: {
        labelCostInCents: 1245,
        speedInDays: 3,
        onDemandPickup: false,
      },
    },
    CHI: {
      parsel: {
        labelCostInCents: 578,
        speedInDays: 3,
        onDemandPickup: true,
      },
      otherApps: {
        labelCostInCents: 654,
        speedInDays: 3,
        onDemandPickup: false,
      },
      retail: {
        labelCostInCents: 1140,
        speedInDays: 3,
        onDemandPickup: false,
      },
    },
    MIA: {
      parsel: {
        labelCostInCents: 607,
        speedInDays: 2,
        onDemandPickup: true,
      },
      otherApps: {
        labelCostInCents: 654,
        speedInDays: 3,
        onDemandPickup: false,
      },
      retail: {
        labelCostInCents: 1140,
        speedInDays: 3,
        onDemandPickup: false,
      },
    },
    LA: {
      parsel: {
        labelCostInCents: 1093,
        speedInDays: 3,
        onDemandPickup: true,
      },
      otherApps: {
        labelCostInCents: 1093,
        speedInDays: 3,
        onDemandPickup: false,
      },
      retail: {
        labelCostInCents: 1860,
        speedInDays: 3,
        onDemandPickup: false,
      },
    },
  },
};
