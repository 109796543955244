import { OreganoIcon } from "@/components/foundation";
import { CheckIcon, XmarkIcon } from "@/public/icons/solid";

export const EnabledIcon = ({ enabled }: { enabled: boolean }) => {
  return enabled ? (
    <div className="inline-block self-start rounded-full  p-3xs">
      <OreganoIcon fill="success" size="sm">
        <CheckIcon />
      </OreganoIcon>
    </div>
  ) : (
    <div className="inline-block self-start rounded-full  p-3xs">
      <OreganoIcon fill="critical" size="sm">
        <XmarkIcon />
      </OreganoIcon>
    </div>
  );
};
